@import '../../assets/scss/variables';

.add-connection {

    .add-connection-header {
        text-align: left !important;
        padding: 20px 40px;
        background-color: rgba(242, 242, 242, 0.5);
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;

        h2 {
            text-align: center;
        }
    }

    .add-connection-body {
        padding: 40px 40px 0 40px;

        form {
            text-align: left;

            .add-connection-group {
                
                label {
                    position: relative;
                    width: 100%;

                    .search-drop {
                        position: absolute;
                        top: 75px;
                        width: 100%;
                        max-height: 200px;
                        overflow-x: auto;
                        background-color: #ffffff;
                        border: 1px solid $light-gray;
                        border-radius: $border-radius;
                        z-index: 9999;
                        visibility: hidden;
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

                        ul {
                            padding: 0;
                            margin: 0;
                            list-style: none;

                            li {
                                padding: 12px;

                                &:hover {
                                    cursor: pointer;
                                    background-color: #f2f2f2;
                                }
                            }
                        }

                        &.drop {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                input {
                    margin-bottom: 10px;

                    &.red-disabled::placeholder {
                        color: #dc3545 !important;
                    }
                    &.red-disabled:disabled {
                        opacity: 1 !important;
                    }
                }

                .add-connection-button-group {
                    text-align: right;

                    button {
                        float: inherit;
                        width: 101px;
                    }
                }
            }
        }
    }
}

#invite-form {
    display: none;
}
.show-invite-button-container {
  display: flex;
}
.show-invite-button {
  border: none;
  background: none;
  color: #3f3ff2;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}

.details {
    padding: 20px 20px 20px 60px;
    height: 200px;
    
    span {
        font-size: 11px;
        color: #999;
        
    }

    p {
        font-size: 13px;
        margin: 3px 0 15px 0;
        font-weight: 500;
        color: #666;
    }
}
.clickable {
    cursor: pointer;
}

.edit-btn {
    position: absolute;
    background: none;
    border: none;
    transform: translateX(-35px);
    color: blue;
}
.edit-delete-container {
    position: absolute;
    top: 20px;
    right: 20px;
}
.delete-btn {
    background: none;
    border: none;
    color: red;
}
.lab-form-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 300px));
    grid-template-areas: 
        "name email cellphone"
        "company company office";
    gap: 10px;
}
.details-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

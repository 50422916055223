.register-clinic-container {

  .form-field {
    padding: 0 5px;
    width: 390px;
  }
  
  .divider {
    padding: 0 5px;
    width: 450px;
  }
}
.crown {
    width: 18px;
    margin-bottom: 10px;
}

.implant {
    height: 20px;
    margin-bottom: 10px;
}

.template,
.bridge
{
    height: 13px;
    margin-bottom: 10px;
}

.cd-header {
    font-size: 11px;
    color: #999;
}

.cd-text {
    font-size: 13px;
    margin: 3px 0 11px 0;
    font-weight: 500;
    color: #666; 
}


.column-container {
    /*height: 100%;*/

    > .row {

        > .column {
            position: relative;

            span {
                font-size: 11px;
                color: #999;
            }

            p {
                font-size: 13px;
                margin: 3px 0 11px 0;
                font-weight: 500;
                color: #666;
            }

            .full-mouth {
                position: relative;
                max-width: 150px;
                left: 10%;
            }

            .layered-tooth {
                position: relative;
                top: 30px;
                max-width: 130px;
            }

            .crown {
                width: 18px;
                margin-bottom: 10px;
            }
            
            .implant {
                height: 20px;
                margin-bottom: 10px;
            }
            
            .template,
            .bridge
            {
                height: 13px;
                margin-bottom: 10px;
            }
        }
    }
}

.order-id-container {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    margin-bottom: -15px;
    padding-bottom: 0;

    i {
        cursor: pointer;
        margin-right: 8px;
        font-size: 18px;
    }
    
    .id {
        cursor: pointer;
        margin-top: 2px;
    }

}

.details {
    padding: 20px;
    height: 200px;
    
    span {
        font-size: 11px;
        color: #999;
    }

    p {
        font-size: 13px;
        margin: 3px 0 15px 0;
        font-weight: 500;
        color: #666;
    }
}

.modal-lab-picker {
    width: 500px;
    padding: 30px;
}
.dialog-button-container {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    margin: 20px 0 0 0;
}
.add-lab-row {
    margin-top: -60px;
}

.case-instructions-p {
    word-wrap: break-word;
}
@import '../../../assets//scss/variables';

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    transition: opacity 0.2s ease-in-out;

    .modal-container {
        position: relative;
        // padding: 20px;
        padding-bottom: 43px;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        min-height: 100px;
        width: 690px;
        background-color: #ffffff;
        border-radius: 5px;        
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

        > i {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;

            &:hover {
                cursor: pointer;
            }
        }

        &.alert {
            top: 200px;
            min-height: 50px;
            width: 300px;
            padding-bottom: 20px;
        }

        .modal-button-group {
            display: flex;
            justify-content: space-evenly;
            padding: 40px;

            button {
                padding: 10px 30px;
                width: 120px;
                border-radius: 5px;
                font-size: 14px;
                color: #999;
                border: 1px solid transparent;
                transition: all 0.2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    color: #333;
                    border: 1px solid $purple;
                }

                &.active {
                    color: #333;
                    border: 1px solid $purple;
                }
            }
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            padding: 20px;       
         
            > span {
                font-weight: 600;
            }
        }

        .modal-body {
            text-align: left;
            padding: 20px;
            width: 100%;        
        }

        .modal-footer {
            width: 100%;
            padding: 20px;
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }
}
.password-reset-container {
    width: 500px;

    img {
        display: block;
        margin: 0 auto;
        width: 195px;
        margin-top: -25px;
    }

    h6 {
        text-align: center;
        margin: 20px 0;
        font-size: 18px;
        margin-bottom: 100px;
    }

    p {
        margin-bottom: 50px;
    }

    form {

        label {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 700;
        }

        input {
            margin-top: 10px;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 20px;
        }

        button {
            float: right;
            margin-left: 20px;
        }
    }
}

.login-btn-container {
    display: flex;
}
.login-btn-container button {
    margin: auto;
}

@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,700,0,200';

.dropdown {
    position: relative;
    min-width: 200px;
    height: 100%;
    padding: 17px 20px;
    border: 1px solid $dark-gray;
    border-radius: $border-radius;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-weight: 500;

    span {
        color: black;
    }

    &.has-icon {
        > span {
            margin-left: 20px;
        }

        > i {
            position: absolute;
            font-size: 21px;
            left: 10px;
            top: 9px;
        }
    }

    .drop {
        position: absolute;
        right: 0;
        top: calc(100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.1s ease-in-out;
        margin-top: 10px;
        border-radius: $border-radius;
        z-index: 9999;
        background-color: $white;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        
        > ul {            
            padding: 0;
            margin: 0;
            width: 100%;
            list-style: none;

            > li {
                padding: 20px 10px;
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    background-color: #f2f2f2;
                }

                &:first-child {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;
                }

                &:last-child {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }

    &:after {
        content: '\e5df';
        font-family: "Material Symbols Outlined";
        display: block;
        transform: rotate(90deg);
        position: absolute;
        right: 15px;
        margin-top: -20px;
        font-size: 120%;
        color: rgba(117,117,117,1);
        transition: transform 0.1s ease-in-out;
    }

    &.show {
        &:after {
            transform: rotate(270deg);
        }

        > .drop {
            opacity: 1;
            visibility: visible;
        }
    }
}
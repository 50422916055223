.filter-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .dropdown {
        .drop {
            width: 100% !important;
        }
    }
}
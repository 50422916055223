.tab {
    display: inline-block;
    vertical-align: top;
    width: 33.33333%;
    text-align: center;
    padding: 25px;
    border-bottom: 3px solid transparent;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #333;
        font-weight: 700;
    }

    &:hover:not(.active) {
        cursor: pointer;
        border-bottom: 3px solid #ebebeb;
    }
}
.date-range-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;

    .drop {
        // width: 772px; // this is when the date-range buttons are uncommented
        width: 619px;

        .drop-body {
            border-bottom: 1px solid #cccccc;

            .pane {
                display: inline-block;
                vertical-align: top;

                &.button-array {
                    ul {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;
                        border-right: 1px solid #ccc;

                        li {
                            padding: 17px 30px;
                            border-top: 1px solid #ccc;
                            transition: background-color 0.2s ease-in-out;

                            &:nth-of-type(1) {
                                border: none;
                            }

                            &:hover {
                                cursor: pointer;
                                background-color: #f2f2f2;
                            }
                        }
                    }
                }

                &.date-picker {   
                    
                    margin: 0 auto;
                    
                    .date-picker-header {
                        padding: 20px;
                        text-align: right;
                        font-size: 11px;
            
                        .date-reset {
                            margin-right: 20px;
                        }
            
                        .date-marker {
                            padding: 8px 4px;
                            border: 1px solid #ccc;     
                            border-radius: 3px;
                            line-height: 15px;
                            i {
                                font-size: 16px;
                                vertical-align: bottom;
                            }
                            .calendar {
                                padding: 0 10px;
                            }       
                            .chevron-left {
                                padding-left: 5px;
                            }       
                            &:nth-of-type(2) {
                                border-right: none;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                            &:nth-of-type(3) {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
            }

            .date-group-selectors {
                display: inline-block;
                vertical-align: top;
            }
            
            .day-picker-range {
                display: inline-block;
                vertical-align: top;
            }
        }
        
        .drop-footer {
            display: flex;
            padding: 10px 20px;
            font-size: 12px;

            i {
                font-size: 16px;
                vertical-align: middle;
                font-weight: bold;
                padding: 0 20px;
            }     
            .drop-footer-label {                    
                width: 50%;

                > span {
                    line-height: 34px;
                }
            }
            .drop-footer-controls {
                text-align: right;
                width: 50%;

                > button {   
                    margin-left: 10px;             
                    padding: 8px 25px;                    
                }
            }                
        }
    }
}
@import '../../../assets/scss/variables.scss';

.dashboard-branding {
    display: inline-block;
    

    a {
        color: $dark-gray; 
        text-decoration: none;

        .dashboard-branding-img {
            display: inline-block;
            width: 40px;
            max-width: 100%;

            img {
                margin-top: 4px;
                max-width: 100%;      
            }
        }

        .dashboard-branding-name {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            margin-left: 10px;
            color: black;

            h1 {
                margin: 0;
                padding: 0;
                letter-spacing: 3px;
                margin-bottom: -10px;
            }

            span {
                top: -20px;
                font-size: 9px;
            }
        }
    }

    .label {
        display: inline-block;
        vertical-align: top;
        border-left: 1px solid #333;
    }
}
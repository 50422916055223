@import '../../assets//scss/variables';

.register-container {
    padding-top: 75px;
    margin: 0 auto;

    img {
        max-width: 50px;
    }

    h6 {
        margin-bottom: 60px !important;
    }

    form {
        p {
            margin-top: -15px;
            margin-left: 10px;
            font-size: 12px;
            color: red;
        }
    }

    &.success {
        padding-top: 75px;
        width: 375px;
        text-align: center;

        i {
            font-size: 75px;
            color: $success;
        }
    }
}
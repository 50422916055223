/**
 * import site wide font family
 */
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700&display=swap');

/**
 * import material design font
 */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/**
 * import all scss files
 */
@import './variables';
@import './layout';
@import './button';
@import './input';
@import './select';
@import './typography';
@import './dropdown';
@import './date-range';
@import './form';

/**
 * global styles
 */
body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    color: $dark-gray
}

* {
    outline: none;
    box-sizing: border-box;
}

span,
p,
img {
    user-select: none;
}

::placeholder {
    color: $light-gray !important;
}
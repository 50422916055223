.DayPicker__withBorder {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.DayPickerKeyboardShortcuts_show {
    display: none !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #a76893;
    border: 1px double #a76893;
    color: $white;
}

.CalendarDay__selected_span {
    background: #f5e1ec;
    border: 1px double #f5e1ec;
    color: $dark-gray;

    &:hover {
        background: #a76893;
        border: 1px double #a76893;
    }
}

.CalendarDay__hovered_span {
    background: #f5e1ec;
    border: 1px double #f5e1ec;

    &:hover {
        background: #a76893;
        border: 1px double #a76893;
        color: $white;
    }
}
@import '../../assets/scss/variables';

.connections-container {
    padding: 0 40px;
    
    .connections {
        height: 850px;
        position: relative;
        .add-connection {
            float: left;
            width: 33.33333%;
            padding: 10px;

            .add-connection-content {
                width: 100%;
                height: 250px;
                border: 1px solid $light-gray;
                border-radius: $border-radius;
                text-align: center;
                padding-top: 80px;
                box-shadow: none;
                transition: box-shadow 0.1s ease-in-out;

                .add-user-icon {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid $dark-gray;

                    i {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    
                }

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                }
            }
        }
    }
}

.connections-clinicpicker-container {
    display: flex;
    margin: 0 10px;
    gap: 10px;
}
.connections-clinicpicker-container > div {
    flex: 1;
}

.connections-pagination {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

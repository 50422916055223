@import '../../../../assets/scss/variables';

.table-data-wrapper {
	position: relative;

	i {
		font-size: 22px;
		top: 20px;
		position: absolute;
		transition: transform 0.2s ease-in-out;

		&.rotate {
			transform: rotate(90deg);
		}

		&.completed {
			color: $success;
		}

		&.pending {
			color: $warning;
		}

		&.cancelled {
			color: $danger;
		}

		&.processing {
			color: $primary;
		}

		&.failed {
			color: $danger;
		}
	}

	.status {
		left: 40px;
	}
	

	&:hover {
		cursor: pointer;
		background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.03) 50%, rgba(255,255,255,1) 100%); 
	}
}
.table-head {
    border-bottom: 1px solid #666;

    span {
        
        font-size: 16px;
    }    

    .table-row {
        border-bottom: none;
    }
}
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 10px;

  .text {
    margin: 2px;
    padding: 5px;
  }
}
input {
    padding: 12px;
    border-radius: $border-radius;
    border: 1px solid $light-gray;
    font-size: 14px;
    transition: border 0.2s ease-in-out;

    &::placeholder {
        color: #ccc;
    }

    // &:focus {
        // border: 1px solid $dark-gray;
    // }

    &.dark-border {
        border: 1px solid $dark-gray;
    }

    &.error {
        color: #721c24 !important;
        background-color: #f8d7da !important;
        border-color: #f5c6cb !important;
    }

    &:-webkit-autofill { 
        -webkit-box-shadow:200px 200px 100px white inset; 
        box-shadow:200px 200px 100px white inset; 
    }

    &:disabled {
        opacity: .5;
        
        &:hover {
          cursor: not-allowed;
        }
    }
}
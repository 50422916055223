form {

    label {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 700;
    }

    input {
        margin-top: 10px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    button {
        float: right;
        margin-left: 20px;
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 44px;
        text-decoration: none;
        color: $dark-gray;

        &:hover {
            text-decoration: underline;
        }
    }

    .input-group {
        .input-group-column {
            display: inline-block;
            width: 50%;
            padding-right: 10px;

            &:last-child {
                padding: 0;
            }

            &.span-1 { 
                width: 8.333333333%;
            }
        
            &.span-2 { 
                width: 16.666666666%;
            }
        
            &.span-3 { 
                width: 25%;
            }
        
            &.span-4 { 
                width: 33.333333333%;
            }
            
            &.span-5 { 
                width: 41.666666666%;
            }
        
            &.span-6 { 
                width: 50%;
            }
        
            &.span-7 { 
                width: 58.333333333%;
            }
        
            &.span-8 { 
                width: 66.666666666%;
            }
        
            &.span-9 { 
                width: 75%;
            }
        
            &.span-10 { 
                width: 83.333333333%;
            }
        
            &.span-11 { 
                width: 91.666666666%;
            }
        
            &.span-12 { 
                width: 100%;
            }
        }
    }
}
.scroll-y {
    overflow-y: scroll;
}
.column {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    height: 100%;
    padding: 10px;
    border-right: 1px solid #999;

    &.no-divider {
        border: none !important;
    }

    &:last-of-type {
        border: none;
    }

    &.span-1 { 
        width: 8.333333333%;
    }

    &.span-2 { 
        width: 16.666666666%;
    }

    &.span-3 { 
        width: 25%;
    }

    &.span-4 { 
        width: 33.333333333%;
    }
    
    &.span-5 { 
        width: 41.666666666%;
    }

    &.span-6 { 
        width: 50%;
    }

    &.span-7 { 
        width: 58.333333333%;
    }

    &.span-8 { 
        width: 66.666666666%;
    }

    &.span-9 { 
        width: 75%;
    }

    &.span-10 { 
        width: 83.333333333%;
    }

    &.span-11 { 
        width: 91.666666666%;
    }

    &.span-12 { 
        width: 100%;
    }
}

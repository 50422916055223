.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-center {
    text-align: center;
}

.text-white {
    color: $white;
}

.text-red {
    color: $danger;
}

.text-success {
    color: $success;
}

.text-bold {
    font-weight: bold;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.hover {
    cursor: pointer;
}

.container-label {
    position: absolute;
    left: 10px;
    top: -20px;
    font-size: 13px;
}
.table-row-drop {    
    opacity: 0;
    height: 1px;
    transition: all 0.2s ease-in-out;

    &.open {
        opacity: 1;
        height: 300px;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.03) 50%, rgba(255,255,255,1) 100%);     
    }
}
.search-container {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;

    i {
        position: absolute;
        left: 15px;
        top: 50%;
        font-size: 20px;
        color: #999;
        transform: translateY(-50%);
    }

    input {
        padding-left: 40px;
        color: #666;
    }
}
@import '../../assets/scss/variables';

.login-container {
    width: 550px;

    img {
        display: block;
        margin: 0 auto;
        width: 110px;
        margin-top: -25px;
    }

    h6 {
        text-align: center;
        margin: 20px 0;
        font-size: 30px;
        margin-bottom: 50px;
        color: black;
    }
}

.forgot-password {
    float: left;
    border: none;
    background: transparent;
    color: #227df2;
}

.text-green {
    color: green;
}

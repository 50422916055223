.renders-grid {

    .renders-grid-box {
        display: inline-block;
        width: 80%;
        height: 140px;
        vertical-align: top;
        text-align: center;
        padding: 5px;

        &.full {
            margin-left: 10%;
        }
        

        &:hover > .renders-item:not(.disabled) {
            cursor: pointer;
            background-color: #999;
        }

        .renders-item {
            position: relative;
            margin: 0 auto;
            margin-top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            height: 80px;
            border-radius: 5px;
            background-color: #ccc;
            z-index: 999;
            transition: background-color 0.2s ease-in-out;

            &.no-background-color {
                background-color: transparent;
                pointer-events: none;
            }

            &.disabled {
                pointer-events: none;
                cursor: not-allowed !important;

                &::after {
                    content: 'No scan found';
                    position: absolute;
                    color: #c0392b;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            i {
                position: absolute;
                bottom: 5px;
                right: 7px;
                font-size: 16px;
            }

            img {
                max-width: 60%;
            }
        }
    }
}

.render-engine-wrapper {
    position: relative;
    height: 400px;
}

.modal-footer-button-group {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.details-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1f;
}

.user-form-container {
    display: grid;
    max-width: 80vw;
    grid-template-columns: repeat(2, minmax(50px, 300px));
    grid-template-areas: 
        "email firstName lab"
        "username lastName clinic"
        "enabled enabled enabled";
    gap: 10px;
}

.flex-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-padding {
    padding: 0 !important;
}

.no-left-padding {
    padding-left: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-30 {
    margin-left: 30px;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.align-right {
    text-align: right;
}

.overflow-x {
    overflow-x: auto;
}

.overflow-y {
    overflow-y: auto;
}

.overflow {
    overflow: scroll;
}

.border {
    border: 1px solid #f2f2f2;
}
img {
  &.clear-thumbnail {
      width: 100%;
      height: 125px;
      object-fit: cover;
      cursor: pointer;
  }
  &.large-clear-thumbnail {
      width: 100%;
      object-fit: cover;
  }
}

.no-image {
  height: 125px;
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.modal-zoom-buttons {
  position: absolute;
  bottom: 0;
  z-index: 100;
}


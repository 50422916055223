.table-heading {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    padding: 0 0 0 20px;
    text-transform: capitalize;

    span {
        line-height: 60px;
    }

    &:nth-of-type(1) {
        padding-left: 80px;
    }

    &:nth-of-type(2) {
        padding-left: 80px;
    }

    &.span-1 { 
        width: 8.333333333%;
    }

    &.span-2 { 
        width: 16.666666666%;
    }

    &.span-3 { 
        width: 25%;
    }

    &.span-4 { 
        width: 33.333333333%;
    }
    
    &.span-5 { 
        width: 41.666666666%;
    }

    &.span-6 { 
        width: 50%;
    }

    &.span-7 { 
        width: 58.333333333%;
    }

    &.span-8 { 
        width: 66.666666666%;
    }

    &.span-9 { 
        width: 75%;
    }

    &.span-10 { 
        width: 83.333333333%;
    }

    &.span-11 { 
        width: 91.666666666%;
    }

    &.span-12 { 
        width: 100%;
    }
}
/**
 * Ori colors
 */
$white: #ffffff;
$dark-white: #f2f2f2;
$darker-gray: #333333;
$dark-gray: #4a4a4a;
$light-gray: #cccccc;
$purple: #933b77;
$darker-purple: #803367;

/*
 * Bootstrap colors
 */
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;

/**
 * features
 */
 $border-radius: .3em;
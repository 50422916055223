.widget-container {
  background-color: #f7f8fa;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.bar-container {
  margin-left: 2px;
}

.text {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  margin-left: -9px;
  padding-bottom: 5px;

  &.first {
    margin-left: 0px;
  }

  &.cost {
    margin-left: 0px;
    padding-top: 8px;
  }
}
@import '../../../assets/scss/variables';

.connections-container {
    padding: 0 40px;
    
    .connections {

        .connection {
            float: left;
            width: 33.33333%;
            padding: 10px;
            position: relative;

            .connection-content {
                width: 100%;
                height: 250px;
                padding: 20px;
                border: 1px solid $light-gray;
                border-radius: $border-radius;
                box-shadow: none;
                transition: box-shadow 0.1s ease-in-out;

                .connection-header {
                    text-align: left;
                    color: $dark-gray;
                }

                .connection-body {

                    .row {
                        position: relative;
                        padding: 12px 0 !important;

                        > i {
                            vertical-align: middle;
                        }

                        > span {
                            margin-left: 20px;
                        }

                        > small {
                            position: absolute;
                            top: 5px;
                            left: 43px;
                        }

                        .address-info {
                            display: inline-block;
                            margin-left: 20px;
                            vertical-align: top;
                        }
                    }
                }

                &:hover {
                    /*cursor: pointer;*/
                    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                }
            }
        }
    }
}       

.connection-card-heading {
    display: grid;
    grid-template-areas: "icon name"
                         "icon company";
    grid-template-columns: 40px 1fr;
}
.delete-connection-btn {
    position: absolute;
    right: 10px;
}
.connection:hover > .delete-connection-btn {
    visibility: visible;
}

.container-button {
    background: none;
    border: none;
}
.delete-connection-icon {
    color: red;
}

.connection-header {
    font-weight: bold;
    font-size: 18px;
}
.connection-subheader {
    margin-left: 0 !important;
}
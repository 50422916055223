.btn {
    padding: 12px 20px;
    color: $dark-gray;
    background-color: $white;
    border: 1px solid $dark-gray;
    border-radius: $border-radius;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out;

    &.btn-icon {
        position: relative;
        padding-left: 45px;

        i {
            position: absolute;
            vertical-align: bottom;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            font-size: 20px !important;
        }
    }

    &.btn-dark {
        color: $white !important;
        border-color: $purple !important;
        background-color: $purple !important;

        &:hover {
            background-color: $darker-purple !important;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $dark-white !important;
    }

    &.btn-gray {
        color: $white !important;
        border-color: $dark-gray !important;
        background-color: $dark-gray !important;

        &:hover {
            background-color: $darker-gray !important;
        }
    }

    &:disabled {
        opacity: .5;
        &:hover {
          cursor: not-allowed;
        }
    }

    &.pagination {
        margin: 1px;
        padding: 5px;
        border: 0;
    }
}

.btn-group {
    .btn {
        border-radius: 0;

        > i {
            font-size: 14px;
            font-weight: bold;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            border-right: 1px solid transparent;
        }

        &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    a {
        .btn {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}

// .pagination-btn {
// padding: 2px;
// // color: $dark-gray;
// // background-color: $white;
// // border: 1px solid $dark-gray;
// // border-radius: $border-radius;
// font-size: 14px;
// transition: background-color 0.2s ease-in-out;

// .reversed {
//     color: grey;
//     padding: 2px;
//     transform: rotate(180deg);
// }
// }